<template>
    <div>
        <div class="d-flex flex-column align-items-center bg-green rounded p-5">
            <span :class="`rounded-circle p-3 text-white bg-white`">
                <b-icon class="d-flex text-purple" icon="chat-left-dots-fill" font-scale="3"></b-icon>
            </span>
            <div class="text-white fs-4 mb-2">Modelos HSM</div>
            <!-- <b-button variant="light" class="text-purple px-4" v-b-modal.modalDepartmentSave>Criar</b-button> -->
        </div>
        <div class="mt-4 bg-white rounded text-purple">
            <div class="d-flex justify-content-between align-items-center border-bottom p-3">
                <div class="fs-5 fw-bold">Modelos</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Modelos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ models.length || 0 }}</div>
                    <b-dropdown ref="dropdownFilters" right menu-class="p-0 mt-2 shadow-sm w-max" toggle-class="border border-dark-subtle rounded-pill px-3 py-2 text-secondary small-text hstack gap-2" variant="white" text="Filtrar">
                        <b-form class="vstack gap-3 p-3" @submit.prevent="getModels(); closeFiltersDropdown()">
                            <b-form-group class="w-100" label="Nome:" label-class="text-purple">
                                <b-form-input type="text" class="border-grey" placeholder="Nome" v-model="filters.name"/>
                            </b-form-group>
                            <b-form-group class="w-100" label="Canal:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.channelId">
                                    <b-form-select-option :value="channel._id" v-for="channel in channels" :key="channel._id">
                                        {{ channel.channelNumber }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-button type="submit" class="text-white" variant="green">Filtrar</b-button>
                        </b-form>
                    </b-dropdown>
                    <!-- <b-form-input type="text" placeholder="Busca por Nome Modelo " @input="filterModels"></b-form-input> -->
                    <b-button class="bg-pink border-0 ms-3" v-b-modal.modalTags>Tags</b-button>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center p-3" v-if="loading">
                <b-spinner />
            </div>
            <div v-else-if="models.length">
                <b-row class="m-0 border-bottom px-3 py-2 gap-2">
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Nome</div>
                    </b-col>
                    <b-col class="p-0" cols="4">
                        <div class="fw-bold">Texto</div>
                    </b-col>
                    <b-col class="p-0" cols="3">
                        <div class="fw-bold">Categoria</div>
                    </b-col>
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Status</div>
                    </b-col>
                    <b-col class="p-0"></b-col>
                </b-row>
                <b-row class="m-0 px-3 py-3 gap-2 border-bottom d-flex align-items-center models" v-for="item in models" :key="item.id">
                    <b-col class="p-0" cols="2">
                        <div class="text-capitalize text-truncate">{{ item.name }}</div>
                    </b-col>
                    <b-col class="p-0" cols="4">
                        <div class="text-capitalize">{{ findBody(item) }}</div>
                    </b-col>
                    <b-col class="p-0" cols="3">
                        <div class="text-capitalize">{{ item.category }}</div>
                    </b-col>
                    <b-col class="p-0 " cols="2">
                        <div class="text-capitalize">{{ formatStatus(item.status) }}</div>
                    </b-col>
                    <b-col class="p-0 d-flex justify-content-end">
                        <b-button variant="green" class="text-white manage-btn ms-0" v-b-tooltip.hover.bottom="{ variant: 'green' }" title="Preview" @click.prevent="selectModel(item)" v-b-modal.modalPreview>
                            <b-icon icon="eye"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="p-3 text-purple text-center" v-else>
                Nenhum modelo encontrado!
            </div>
        </div>
        <b-modal id="modalTags" ref="modalTags" title="Tags" hide-footer body-class="p-0" header-class="py-0">
            <div class="border-bottom py-2 px-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modalPreview" ref="modalPreview" title="Preview" hide-footer body-class="p-0" header-class="py-0" v-if="this.selectedModel">
            <div class="p-3">
                <Whatsapp :messages="[{ type: 'template', body: selectedModel, fromMe: true }]" :height="'50vh'" :mediaURL="mediaURL" />
                <!-- <div class="mt-3">
                    <b-button class="btn-success" @click="$bvModal.hide('modalPreview')">Fechar</b-button>
                </div> -->
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'mediaURL'
    ],
    data() {
        return {
            selectedModel: null,
            models: [],
            filters: {},
            channels: [],
            loading: false,
            tags: [
                {
                    name: "DTN",
                    description: "Dia, Tarde ou Noite"
                },
                {
                    name: "NOME_CLIENTE",
                    description: "Nome do Contato"
                },
                {
                    name: "APELIDO_CLIENTE",
                    description: "Apelido do Contato"
                },
                {
                    name: "EMAIL_CLIENTE",
                    description: "Email do Contato"
                },
                {
                    name: "CODIGO_CLIENTE",
                    description: "CÃ³digo do Contato"
                },
            ]
        }
    },
    mounted() {
        if (this.user.channel) {
            this.channels.push(this.user.channel)
            this.getModels()
        } else {
            this.loading = true
            setTimeout(() => {
                if (this.user.channel)
                    this.channels.push(this.user.channel)
                this.getModels()
            }, 2000);
        }
    },
    methods: {
        selectModel(model) {
            this.selectedModel = model
        },
        async getModels() {
            this.loading = true
            let models = []

            if (this.filters.channelId) {
                const channelModels = await this.getModelsByChannelId(this.filters.channelId)
                models.push(...channelModels)
            } else {
                if (this.channels.length <= 1) {
                    const secondaryChannels = await this.getSecondaryChannels()
                    this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup' || channel.apiType === 'cloud'))
                }
    
                const promise = this.channels.map(async channel => {
                    if (channel.apiType === 'gupshup' || channel.apiType === 'cloud') {
                        const channelModels = await this.getModelsByChannelId(channel._id)
                        models.push(...channelModels)
                    }
                })
                await Promise.all(promise)
            }

            if (this.filters.name) {
                models = models.filter(el => el.name.toLowerCase().includes(this.filters.name))
            }

            this.models = models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

            this.loading = false
        },
        async getModelsByChannelId(channelId) {
            const modelsResp = await api.getMessageTemplates(channelId)

            if (modelsResp.statusCode == 200)
                return modelsResp.templates

            return []
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            
            if(resp.statusCode === 200)
                return resp.channels

            return []
        },
        findBody(item) {
            const body = item.components.find(el => el.type == "BODY")
            if(body?.text)
                return body.text
            else return "- - - -"
        },
        formatStatus(status) {
            switch(status) {
                case 'APPROVED':
                    return "Aprovado"
                case 'PENDING':
                    return "Em Aprovação"
                case 'REJECTED':
                    return "Reprovado"
                default:
                    return "- - - -"
            }
        },
        async filterModels(filter) {
            await this.getModels()
            this.models = this.models.filter(el => el.name.toLowerCase().includes(filter))
        },
        closeFiltersDropdown() {
            if (this.$refs.dropdownFilters)
                this.$refs.dropdownFilters.hide(true)
        }
    },
}
</script>

<style scoped>
    .models:last-child, #modalTags div:last-child {
        border: none !important;
    }
</style>